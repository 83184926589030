@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html {
  scroll-behavior: smooth;
}

body {
  user-select: none;
  margin: 0;
}

header,
footer {
  text-align: right;
}

.glassmorphism {
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px;
}

.glassmorphism h2 {
  font-weight: bold;
  margin-bottom: 8px;
}

.glassmorphism p {
  font-size: 14px;
  color: #666;
}

:focus {
  outline: 2px solid #198268;
}